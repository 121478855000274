import { initTrack } from '@gaodingx/shared/lib/lib/tracker';
import { trackerWind, tracker } from '@gaoding/gd-tracker';
import { abtest } from './services/ab-test-v2';
import { pageExperimentV2 } from "./abtest/root-page-abtest";

import { userSession } from './services/user-session';
import { merge } from 'lodash';
import env from '@/services/env';

export const initTracker = async (app) => {
    initTrack({
        router: app.$router,
        tracker(data) {
            const { event, ...other } = data;
            trackerWind.trackEvent(event, other);

        },
        trackerSet(eventStr, params) {
            let trackerData = trackerWind.get(eventStr);
            const newData = merge(trackerData, params);
            trackerWind.set(eventStr, newData);
        },
    });
    const isProd = process.env.APP_STAGE === 'production';
    const showLog = window.location.search.includes('__tracker_log=1');
    const debugMode = window.location.search.includes('__tracker_debug=1');
    const hmtProjectId = 'fdafb975c7b15a614fe2dd7716c44e87';
    const pageExposeEventId = 39003;
    const isShowNewPage = await pageExperimentV2()
    const projectName = isShowNewPage ? 'gd_gdesign_web' : 'gd_web';
    const trackerOptions = {
        _hmt: {
            projectId: hmtProjectId
        },
        wind: {
            project: projectName || 'gd_web',
            showLog,
            version: '4.0.0',
            env: env.env || 'prod',
            transport: showLog ? 'image' : '',
            login: true,
            plugins: [
                ['abtest-data-tracker', { abtest }],
                'utm-data-tracker',
                ['router-change-tracker', { app, eventId: pageExposeEventId }],
                'page-duration-tracker',
            ]
        }
    };

    tracker.setup(trackerOptions, isProd, debugMode);

    await userSession.init();
    if (userSession.user) {
        tracker.login(userSession.user.id, {
            type: userSession.user.type,
            vipLevel: userSession.isVIP ? userSession.user.vip.level : undefined
        });
    }
};

