<template>
    <div class="gaoding-uupoop-root-v2-matting-image">
        <article>
            <video
                muted
                loop
                autoplay
                width="687"
                src="https://st-gdx.dancf.com/gaodingx/4368/configs/system/20211215-134509-3209.mp4"></video>
            <div class="title">
                <h5>AI"黑科技"，一键抠图</h5>
                <TrackButton :trackData="{
                    page_name:'UUPOOP页',
                    module_name:'抠图及一站式工具',
                    button_name: '马上抠图'
                }">
                    <AButton
                        ref="button"
                        @click="handleClick"
                        class="matting-image-btn"
                    >
                        马上抠图
                    </AButton>
                </TrackButton>
            </div>
        </article>
        <article class="icon-detail">
            <div
                :class="{'all-title': index === 0, 'unit-title': index !== 0}"
                v-for="(item, index) in toolList"
                :key="item.title"
                @click="handleALink(item)"
            >
                <TrackButton :trackData="{
                    page_name:'UUPOOP页',
                    module_name:'抠图及一站式工具',
                    button_name: item.title
                }">
                    <div>
                        <img :src="item.icon" :alt="item.title" v-if="item.icon">
                        <h5>{{item.title}}</h5>
                        <p class="desc">
                            <span>{{item.desc}}</span>
                            <a :href="item.href" v-if="item.link" target="_blank">{{item.link}} ></a>
                        </p>
                    </div>
                </TrackButton>
            </div>
        </article>
    </div>
</template>

<script>
import { Button } from '@gaoding/gd-antd';
import { toolList } from './constant.js';
import TrackButton from "../../trackers/track-button"
import { linkTo } from '../../utils/query.js';
import { GAODING_UUPOOP_ENV } from "../../services/env"


export default {
    name: 'uupoop-root-v2-matting-image',
    components: {
        AButton: Button,
        TrackButton,
    },
    data() {
        return {
            toolList,
            gaodingUrl: GAODING_UUPOOP_ENV.gaodingUrl
        }
    },
    methods: {
        handleClick() {
           linkTo(`${this.gaodingUrl}/uupoop?open_koutu=1`,'_blank')
        },
        handleALink(item) {
            const href = item.href;
            if(href) {
                linkTo(`${this.gaodingUrl}${href}`,'_blank')
            }
        }
    }
}
</script>

<style lang="less">
.gaoding-uupoop-root-v2-matting-image {
    @layout-width: 1200px;
    @scale: 0.95;

    margin: auto;
    width: @layout-width;
    min-width: @layout-width;
    padding: 128px 0;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 1440px) {
        width: @layout-width * @scale;
        min-width: @layout-width * @scale;
    }

    video {
        width: 100%;
        border-radius: 12px;
    }
    article {
        position: relative;
        width: 687px;
        height: 582px;

        @media screen and (max-width: 1440px) {
            width: calc(687px * @scale);
            height: calc(582px * @scale);
        }
        .title {
            position: absolute;
            top: 48px;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
            h5 {
                font-style: normal;
                font-weight: 600;
                font-size: 32px;
                line-height: 40px;
                color: #FFFFFF;
                margin-bottom: 0px;
            }
            .matting-image-btn {
                width: 200px;
                height: 52px;
                background: rgba(179, 179, 179, 0.1);
                border: 1px solid #FFFFFF;
                border-radius: 2px;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 52px;
                text-align: center;
                color: #FFFFFF;
                margin-top: 36px;
                display: inline-block;
                text-decoration: none;
                &:hover {
                    background: rgba(255, 255, 255, 0.2);
                    border: 1px solid rgba(255, 255, 255, 0.8);
                }
                &:active {
                    background: rgba(255, 255, 255, 0.05);
                    border: 1px solid #FFFFFF;
                }
            }
        }
    }
    .icon-detail {
        display: grid;
        grid-template-columns: 249px 246px;
        grid-template-rows: 172px 205px 205px;
        background: #f6f7f9;
        border-radius: 12px;
        width: 495px;
        height: 582px;

        @media screen and (max-width: 1440px) {
            grid-template-columns: calc(249px * @scale) calc(246px * @scale);
            grid-template-rows: calc(172px * @scale) calc(205px * @scale) calc(205px * @scale);
            width: calc(495px * @scale) !important;
            height: calc(582px * @scale);
        }

        img {
            width: 64px;
            height: 64px;
        }
        h5 {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 0px;
            margin-top: 12px;
        }
        p {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            color: #4c535c;
            margin-bottom: 0px;
            margin-top: 12px;
        }
        div {
            justify-self: center;
            align-self: center;
            text-align: center;
            a {
                color: #4c535c;
                text-decoration: none;
            }
            &:not(:first-child):nth-of-type(2n+1) {
                border-left: 0.5px solid rgba(0, 0, 0, 0.08);
            }
        }
        div + div {
            width: 100%;
            height: 100%;
            border-top: 0.5px solid rgba(0, 0, 0, 0.08);
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
        }
        .all-title {
            cursor: pointer;
            grid-column-start: 1;
            grid-column-end: 3;
            h5 {
                font-style: normal;
                font-weight: 600;
                font-size: 32px;
                line-height: 40px;
                color: #222529;
                margin: 0px;
            }
            p {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                color: #4c535c;
                margin-top: 4px;
                margin-bottom: 0px;
            }
        }
        .unit-title {
            cursor: pointer;
            &:hover {
                background:#f1f2f4;
                cursor: pointer;
                p, a, span, h5 {
                    color: #222529
                }
            }
        }
    }
}
</style>
