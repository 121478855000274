// abtest 的公共文件
// abtest 的项目地址：https://abtest.hlgdata.com/index.html#/dashboard

import abTest from '../services/ab-test-v2';
import { eq } from 'lodash';
import to from 'await-to-js'

// abtest 地址 https://abtest.hlgdata.com/index.html#/expItem?appId=10&expId=451
export const pageExperiment = async () => {
    const GROUP_MAP = {
        CONTROL_GROUP: 'old_page', // 对照组旧首页
        EXPERIENCE_GROUP: 'new_page' // 实验组新首页
    };
    const EXPERIENCE_VARIABLE = 'uupoopNewPageAB'; // 实验变量

    const [err, data] = await to(abTest.getValue(EXPERIENCE_VARIABLE));
    if (err) {
        return true;
    }
    const existExp = data;
    const isExperienceGroup = eq(existExp, GROUP_MAP.EXPERIENCE_GROUP); // 是否是实验组
    return isExperienceGroup;
};


